const SocialIcons = () => {
  const styles = {
    icon: {
      textDecoration: "none",
      fontSize: "22px",
      padding: "10px",
      transition: "0.2s ease-in",
    },
  };

  return (
    <div className="socialIcons" style={styles.socialIcons}>
      <a className="icon" target={'_blank'} style={styles.icon} href="https://github.com/HayaAbdullahM" rel="noreferrer">
        <i className="fa-brands fa-github" aria-hidden="true" title="Haya Al-Mossaeed' GitHub Profile"></i>
      </a>
      <a className="icon" target={'_blank'} style={styles.icon} href="https://www.linkedin.com/in/haya-abdullah-al-mossaeed/" rel="noreferrer">
        <i className="fa-brands fa-linkedin" aria-hidden="true" title="Haya Al-Mossaeed' LinkedIn Profile"></i>
      </a>

      <a className="icon" target={'_blank'} style={styles.icon} href="https://twitter.com/H_ALMOSSAEED" rel="noreferrer">
        <i className="fa-brands fa-twitter" aria-hidden="true" title="Haya Al-Mossaeed' Twitter Profile"></i>
      </a>
      <a className="icon" target={'_blank'} style={styles.icon} href="https://www.behance.net/HAALMOSSAED" rel="noreferrer">
        <i className="fa-brands fa-behance" aria-hidden="true" title="Haya Al-Mossaeed' Twitter Profile"></i>
      </a>
    </div>
  );
};

export default SocialIcons;
