
import { useState } from 'react';

const useEmail = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendEmail = async (email, subject, message, name) => {


        try {
            setError(null);
            setLoading(true);
            const response = await fetch('https://hayamossaeed.onrender.com/', {
                mode: 'no-cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    subject,
                    message,
                    name
                })
            })

            if (!response.ok) {
                throw new Error('Something went wrong , please try again')
            }
            setLoading(false);
            return true
        } catch (e) {
            setLoading(false);
            setError(e.message);
            console.log(e.message);
            return false
        }
    }

    return { sendEmail, loading, error }
}

export default useEmail;