import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  const personalDetails = {
    name: "Haya Al-Mossaeed",
    location: "Dhaharan, SA",
    email: "haya.almossaeed@gmail.com",
    availability: "Open for work",
    brand:
      "My multifaceted skillset, encompassing technical expertise, creative aptitude, and design background, empowers me to approach each project from a user-centric perspective, ultimately resulting in the creation of highly efficacious digital products.",
  };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
