import { useInView } from "react-intersection-observer";
import { color, motion } from "framer-motion";
import { useState } from "react";
import useEmail from '../hooks/useEmail';
import Loading from '../components/Loading'
import { toast } from 'react-toastify';

import emailjs from '@emailjs/browser';

const Form = () => {
  const { sendEmail, loading, error } = useEmail();
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });



  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    access_key: "4f412a8f-0a11-4ba9-8e37-e12661f36602",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const data = {
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    }

    await emailjs.send(
      'service_jzj2am5', 'template_x87m5ul', {
      from_name: data.name,
      to_name: 'Haya',
      from_email: data.email,
      to_email: 'haya.almossaeed@gmail.com',
      message: `Subject: ${data.subject}
                Message: ${data.message}
                From: ${data.email}`,
    },
      'wEsV2Fx8BwIdq5rPi'
    ).then(() => {
      toast.dark('Message Sent Successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'dark-toast'

      });
      setFormData({
        ...formData,
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    })





  };

  return (
    <motion.form
      action=""
      ref={ref}
      className="contactForm"
      initial={{ x: "-10vw", opacity: 0 }}
      animate={inView ? { x: 0, opacity: 1 } : { x: "-10vw", opacity: 0 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      onSubmit={handleSubmit}
    >
      <h4 className="contentTitle">Message Me</h4>
      <div className="col-12 col-md-6 formGroup" style={{ display: "inline-block" }}>
        <input
          type="text"
          className="formControl"
          onChange={handleChange}
          value={formData.name}
          id="contactName"
          name="name"
          placeholder="Name"
          required
        />
      </div>
      <div className="col-12 col-md-6 formGroup" style={{ display: "inline-block" }}>
        <input
          type="email"
          className="formControl"
          onChange={handleChange}
          value={formData.email}
          id="contactEmail"
          name="email"
          placeholder="Email"
          required
        />
      </div>
      <div className="col-12 formGroup">
        <input
          type="text"
          className="formControl"
          onChange={handleChange}
          value={formData.subject}
          id="contactSubject"
          name="subject"
          placeholder="Subject"
          required
        />
      </div>
      <div className="col-12 formGroup">
        <textarea
          className="formControl"
          onChange={handleChange}
          value={formData.message}
          name="message"
          id="contactMessage"
          rows="5"
          placeholder="Message"
          required
        ></textarea>
      </div>
      <div className="col-12 formGroup formSubmit">
        {
          <button className="btn" disabled={loading}>{"Send Message"}</button>
        }

      </div>
    </motion.form>
  );
};

export default Form;
